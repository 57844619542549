import useAppLogger from '@/composables/useAppLogger';
import useI18nRoute from '@/composables/useI18nRoute';
import { useStoreAppDataIndex } from '@/stores/app/data';

export default function useSeo() {
    const dataIndex = useStoreAppDataIndex();

    const getTitle = (value: string) => {
        return value + ' - ' + dataIndex.data.config?.NAME;
    };

    const getMetaOg = (item: { title: string, description: string|null|undefined, image: string|null|undefined, url: string}) => {
        const data = [];
        // data.push({ name: 'og:title', content: () => item.title });
        data.push({ name: 'og:type', content: 'website' });
        data.push({ name: 'og:title', content: item.title });
        data.push({ name: 'og:url', content: item.url });
        if (item.description !== undefined && item.description !== null) {
            data.push({ name: 'og:description', content: item.description });
        }
        if (item.image !== undefined && item.image !== null) {
            data.push({ name: 'og:image', content: item.image });
        }
        return data;
    };

    const getMetaTwitter = (item: { title: string, description: string|null|undefined, image: string|null|undefined, url: string}) => {
        const data = [];
        data.push({ name: 'twitter:card', content: 'summary_large_image' });
        data.push({ name: 'twitter:title', content: item.title });
        data.push({ name: 'twitter:url', content: item.url });
        if (item.description !== undefined && item.description !== null) {
            data.push({ name: 'twitter:description', content: item.description });
        }
        if (item.image !== undefined && item.image !== null) {
            data.push({ name: 'twitter:image', content: item.image });
        }
        return data;
    };

    const getMeta = (item: { title: string, description: string|null|undefined, image: string|null|undefined, url: string}) => {
        let data = [];
        data.push({ name: 'url', content: item.url });
        if (item.description !== undefined && item.description !== null) {
            data.push({ name: 'description', content: item.description });
        }
        if (item.image !== undefined && item.image !== null) {
            data.push({ name: 'image', content: item.image });
            data.push({ name: 'vk:image', content: item.image });
        }
        const og = getMetaOg(item);
        data = [...data, ...og];

        const twitter = getMetaTwitter(item);
        data = [...data, ...twitter];

        return data;
    };

    const article = (item: any) => {
        let title = item.meta.title ?? item.title;
        title = getTitle(title);
        const data = {
            title,
            meta: getMeta({
                title,
                description: item.meta.description,
                image: item.image,
                url: useI18nRoute().external(useI18nRoute().article(item)),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('article', data.title);
        return data;
        // useHead({
        //     title: () => item.meta.title ?? item.title,
        //     // titleTemplate: () => ' % - heio',
        //     meta: [
        //         {
        //             name: 'description',
        //             content: () => item.meta.description ?? '',
        //         },
        //     ],
        // });
    };
    const groupCategory = (item: any, parent: any) => {
        let title = item.meta.title + ' / ' + parent.meta.title;
        title = getTitle(title);
        const data = {
            title,
            meta: getMeta({
                title,
                description: item.meta.description,
                image: item.meta.image,
                url: useI18nRoute().external(useI18nRoute().page(item)),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('group', data.title);
        return data;
    };
    const group = (item: any) => {
        let title = item.meta.title ?? item.title;
        title = getTitle(title);
        const data = {
            title,
            meta: getMeta({
                title,
                description: item.meta.description,
                image: item.meta.image,
                url: useI18nRoute().external(useI18nRoute().page(item)),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('group', data.title);
        return data;
    };
    const page = (item: any) => {
        let title = item.meta.title ?? item.title;
        title = getTitle(title);
        const data = {
            title,
            meta: getMeta({
                title,
                description: item.meta.description,
                image: item.meta.image,
                url: useI18nRoute().external(useI18nRoute().page(item)),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('page', data.title);
        return data;
    };
    const tag = (item: any) => {
        let title = `#${item.slug}`;
        title = getTitle(title);
        const data = {
            title,
            meta: getMeta({
                title,
                description: null,
                image: null,
                url: useI18nRoute().external(useI18nRoute().tag(item)),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('page', data.title);
        return data;
    };
    const index = (config: any) => {
        const title = getTitle(config?.META?.title ?? '');
        const data = {
            title,
            meta: getMeta({
                title,
                description: config?.META?.description,
                image: config?.LOGO.light,
                url: useI18nRoute().external(useI18nRoute().index()),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('index', data.title);
        return data;
    };
    const search = (config: any) => {
        const title = getTitle('Поиск');
        const data = {
            title,
            meta: getMeta({
                title,
                description: config?.META?.description,
                image: config?.LOGO.light,
                url: useI18nRoute().external(useI18nRoute().index()),
            }),
            htmlAttrs: {
                lang: 'ru',
            },
        };
        useAppLogger().seo('index', data.title);
        return data;
    };
    return {
        article,
        group,
        groupCategory,
        index,
        search,
        page,
        tag,
    };
}
