const APP_URL = import.meta.env.VITE_APP_URL;

export default function useI18nRoute() {
    const absolute = true;

    const external = (url: string) => {
        return `${APP_URL}${url}`;
    };

    const article = (item: any) => {
        return `/articles/${item.id}/${item.slug}`;
    };
    const group = (item: any) => {
        return `/${item.slug}`;
    };
    const page = (item: any) => {
        return `/${item.slug}`;
    };
    const tag = (item: any) => {
        return `/tags/${item.slug}`;
    };

    const index = () => {
        return '/';
    };

    return {
        external,
        index,
        article,
        group,
        page,
        tag,
    };
}
